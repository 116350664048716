import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/success/SuccessPage.css';
import { REACT_APP_HOST_HOOKS_PROD } from '../../config/config';

const SuccessPage = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  
  useEffect(() => {
      const fetchData = async () => {
          try {
              setIsLoading(true);

              const params = new URLSearchParams(window.location.search);

              const paymentId = params.get('payment_id');

              const options = {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                  }
              };
            
              const response = await fetch(`${REACT_APP_HOST_HOOKS_PROD}/api/mercado-pago/capture-order?payment_id=${paymentId}`, options);
              
              // Verifica si la solicitud fue exitosa
              if (!response.ok) {
                  throw new Error(`Error en la solicitud: ${response.status}`);
              }
              
              // Convierte la respuesta en JSON
              const data = await response.json();

              console.log(data,'data')

              setData({
                idTransaction: response.idTransaction,
                carrito: response.carrito,
                email: response.email,
                total: response.total
              });

              setEmailSent(true);
              setIsLoading(false);
          } catch (error) {
              console.error('Error al obtener el carrito');
              setIsLoading(false);
          }
      };
      fetchData();
  }, []);


  return (
    <section>
        <div className='backgoundSuccess'>
            <h2 className='h2-success'>¡Compra realizado con éxito!</h2>
        </div>

        <div className="success-page-container">



            {isLoading ? (
              <p>Cargando detalles de la compra...</p>
            ) : data?.carrito ? (
                <>
                <h3 className='p-success'>¡Gracias por tu compra!</h3>
              <div className='ticket'>
                <h4>Detalles de la compra:</h4>
                <p><strong>ID:</strong> {data.idTransaction}</p>
                <ul>
                  {data.carrito.map((item, index) => (
                    <li key={index}>
                      <strong>Producto:</strong> {item.title} <br />
                      <strong>Descripción:</strong> {item.description} <br />
                      <strong>Categoría:</strong> {item.category_id} <br />
                      <strong>Cantidad:</strong> {item.quantity} <br />
                      <strong>Precio unitario:</strong> ${item.unit_price} <br />
                    </li>
                  ))}
                </ul>
                <p><strong>Total:</strong> ${data.total}</p>
              </div>
              </>
            ) : (
              <h2 className='failed-transaction'>No hay datos de compra.</h2>
            )}


            {emailSent &&       <p>
              <span role="img" aria-label="success" style={{ color: 'green', marginRight: '8px' }}>✔️</span>
              También te enviamos el enlace al correo electrónico {data?.email}
            </p>}
            
            <p className='p-success'>Si hay algún problema comunicate por WhatsApp.</p>
            
            <img 
                src={`logotypeRedondos.webp`} 
                alt="Logo de la empresa" 
                className="company-logo"
            />
            <Link to="/" className="home-link">
                Volver a Inicio
            </Link>
        </div>
       
    </section>

  );
};
  export default SuccessPage;