import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import Pago from './Pago.jsx';
import '../../styles/checkout/checkout.css';
import { useCart } from '../../context/cartContext.js';
import PaymentMethods from './PaymentMethods.jsx';

const DataForm = ({ personalInfo, setPersonalInfo, shippingInfo, setShippingInfo, billingInfo, setBillingInfo, isFormComplete, carrito }) => {
  const { updateShippingCost } = useCart();
  const [shippingCost, setShippingCost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState(null);
  const [suburbs, setSuburbs] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const [useShippingAddress, setUseShippingAddress] = useState(true);

  useEffect(() => {
    setBillingInfo(prev => ({ ...prev, useShippingAddress }));
  }, [useShippingAddress]);

  const handleInputChange = (e, setter) => {
    const { name, value } = e.target;
    setter(prev => ({ ...prev, [name]: value }));
  };

  const fetchZipCodeData = async () => {
    if (!shippingInfo.postalCode) return;

    setIsLoading(true);
    setError(null);
    setSuburbs([]);
    setLoadingMessage('Calculando costo de envío...');

    try {
      const response = await fetch(`https://geocodes.envia.com/zipcode/AR/${shippingInfo.postalCode}`);
      const data = await response.json();

      if (data[0]?.state) {
        setSelectedProvince(data[0].state.name);
        setShippingInfo(prev => ({ ...prev, province: data[0].state.name, city: '' }));

        if (Array.isArray(data[0].suburbs)) {
          setSuburbs(data[0].suburbs);
        }

        // Cálculo del costo de envío según la provincia seleccionada
        const cost = (data[0].state.name === 'Buenos Aires' || data[0].state.name === 'Capital Federal') ? 6500 : 8500;
        setShippingCost(cost);
        updateShippingCost(cost);

      } else {
        setError("No se encontró información para el código postal proporcionado.");
      }
    } catch {
      setError("Error al obtener la información del código postal.");
    } finally {
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const handleBillingCheckbox = () => {
    setUseShippingAddress(!useShippingAddress);
    if (!useShippingAddress) {
      setBillingInfo(shippingInfo);
    } else {
      setBillingInfo({
        street: '', number: '', floor: '', apartment: '', postalCode: '', city: '', province: ''
      });
    }
  };

  return (
    <div className="checkout-form">
      
      {/* Información Personal */}
      <div className="form-section">
        <h3>Información Personal</h3>

        <div className="container-names">
          {['name', 'lastName'].map(field => (
            <div key={field} className="card flex justify-content-center card-names">
              <FloatLabel>
                <InputText 
                  id={field}
                  name={field}
                  type="text"
                  value={personalInfo[field]} 
                  onChange={e => handleInputChange(e, setPersonalInfo)} 
                />
                <label htmlFor={field}>
                  {field === 'name' ? 'Nombre' : 'Apellido'}
                  <span className="asterisk">*</span>
                </label>
              </FloatLabel>
            </div>
          ))}
        </div>
        
        {/* Campo de Email */}
        <div className="container-email-dni">
          <div className="card flex justify-content-center card-email-dni">
            <FloatLabel>
              <InputText 
                id="email"
                name="email"
                type="email"
                value={personalInfo.email} 
                onChange={e => handleInputChange(e, setPersonalInfo)} 
              />
              <label htmlFor="email" className='label-email'>
                Email<span className="asterisk">*</span>
              </label>
            </FloatLabel>
          </div>

          <div className="card flex justify-content-center card-email-dni">
            <FloatLabel>
              <InputText 
                id="dni"
                name="dni"
                type="text"
                value={personalInfo.dni} 
                onChange={e => handleInputChange(e, setPersonalInfo)} 
              />
              <label htmlFor="dni">
                DNI<span className="asterisk">*</span>
              </label>
            </FloatLabel>
          </div>
        </div>
        
        {/* Contenedor para Código de Área y Número de Teléfono */}
        <div className="container-phone">
          {['areaCode', 'phoneNumber'].map(field => (
            <div key={field} className="card flex justify-content-center card-phone">
              <FloatLabel>
                <InputText 
                  id={field}
                  name={field}
                  type="text"
                  value={personalInfo[field]} 
                  onChange={e => handleInputChange(e, setPersonalInfo)} 
                />
                <label htmlFor={field}>
                  {field === 'areaCode' ? 'Código de Área' : 'Número de Teléfono'}
                  <span className="asterisk">*</span>
                </label>
              </FloatLabel>
            </div>
          ))}
        </div>
      </div>

      {/* Dirección de Envío */}
      <div className="form-section">
        <h3>Dirección de Envío</h3>
        <div className="container-codArea">
          <div className="card flex justify-content-center cardCodArea">
            <FloatLabel>
              <InputText 
                id="postalCode" 
                name="postalCode"
                value={shippingInfo.postalCode} 
                onChange={e => handleInputChange(e, setShippingInfo)} 
              />
              <label htmlFor="postalCode">Código Postal <span className="asterisk">*</span></label>
            </FloatLabel>

          </div>
          <div className='cardCodArea'>
              <Button onClick={fetchZipCodeData} className="mt-2">Buscar por código postal</Button>
          </div>

        </div>

        <p className='p-black'>
          {isLoading ? (
            <span>{loadingMessage}</span>
          ) : error ? (
            <span>{error}</span>
          ) : (
            <p>
            Costo de envío: <span className='span-cost-shipping'>$ {shippingCost} ARS</span> 
            </p>          
          )}
        </p>



        <div className="container-state-city">
          <div className="card flex justify-content-center card-state-city">
            <FloatLabel>
              <InputText 
                id="province" 
                name="province"
                value={selectedProvince} 
                readOnly 
                disabled={isLoading || !selectedProvince}
              />
              <label htmlFor="province">Provincia <span className="asterisk">*</span></label>
            </FloatLabel>
          </div>

          {suburbs.length > 0 && (
            <div className="card flex justify-content-center card-state-city">
              <div className='container-city'>
                <label htmlFor="city">Ciudad <span className="asterisk">*</span></label>
                <Dropdown 
                  id="city" 
                  name="city"
                  value={shippingInfo.city} 
                  options={suburbs} 
                  onChange={e => setShippingInfo(prev => ({ ...prev, city: e.value }))}
                  placeholder="Seleccioná tu ciudad"
                  className="w-full md:w-14rem city" 
                  required
                  disabled={isLoading}
                />
              </div>
            </div>
          )}

        </div>

        <div className='container-adress'>
        {['street', 'number', 'floor', 'apartment'].map(field => (
          <div key={field} className="card flex justify-content-center card-adress">
            <FloatLabel>
              <InputText 
                id={field} 
                name={field}
                value={shippingInfo[field]} 
                onChange={e => handleInputChange(e, setShippingInfo)} 
                disabled={isLoading || !shippingInfo.city}
              />
              <label htmlFor={field}>
                {field === 'street' ? 'Calle' : field === 'number' ? 'Número' : field === 'floor' ? 'Piso' : 'Departamento'}
                {field !== 'floor' && field !== 'apartment' && <span className="asterisk">*</span>}
              </label>
            </FloatLabel>
          </div>
        ))}
        </div>

        
      </div>

      <div className="form-section">
        <h3>Dirección de Facturación</h3>
        <div className="card flex align-items-center checkbox-billing">
          <Checkbox 
            inputId="useShippingAddress" 
            checked={useShippingAddress} 
            onChange={handleBillingCheckbox} 
          />
          <label htmlFor="useShippingAddress">Usar dirección de envío</label>
        </div>

        <div className='container-facturacion'>
          {!useShippingAddress && (
            ['street', 'number', 'floor', 'apartment', 'postalCode', 'province', 'city'].map(field => (
              <div key={field} className="card flex justify-content-center card-facturacion">
                <FloatLabel>
                  <InputText 
                    id={`billing${field}`} 
                    name={field}
                    value={billingInfo[field]} 
                    onChange={e => handleInputChange(e, setBillingInfo)} 
                  />
                  <label htmlFor={`billing${field}`}>
                    {field === 'street' ? 'Calle' : field === 'number' ? 'Número' : field === 'floor' ? 'Piso' : 
                     field === 'apartment' ? 'Departamento' : field === 'postalCode' ? 'Código Postal' : field === 'province' ? 'Provincia' : 'Ciudad'}
                    {field === 'postalCode' && <span className="asterisk">*</span>}
                  </label>
                </FloatLabel>
              </div>
            ))
          )}
        </div>

  
      </div>

      {/* Pago */}
      <div className="form-section">
        <h3>Pago</h3>
        {isFormComplete() ? (
          <Pago personalInfo={personalInfo} shippingInfo={shippingInfo} billingInfo={billingInfo} />
        ) : (
          <p className='p-black'>Por favor, complete toda la información antes de proceder con el pago.</p>
        )}
      </div>

      <div className='container-payment-methods-mobile'>
        <PaymentMethods totalPrice={carrito.totalPrice} />
      </div>
    </div>

  );
};

export default DataForm;
