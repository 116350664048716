import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import '../../styles/breadCrum/breadCrum.css';

const BreadCrum = () => {
  const location = useLocation();
  const { category, productName } = useParams();

  // Definir las rutas conocidas de la aplicación
  const routeNames = {
    '/': 'Home',
    '/productos': 'Productos',
    '/checkout': 'Pago',
    '/faqs': 'Preguntas frecuentes',
  };

  // Obtener las partes de la URL
  const pathParts = location.pathname.split('/').filter((part) => part);

  // Comprobar si el último segmento es un ID de MongoDB (24 caracteres) o si no existe
  const isLastPartId = pathParts[pathParts.length - 1]?.length === 24;

  // Filtrar los segmentos para evitar que se incluyan números o partes vacías
  const breadcrumbParts = isLastPartId ? pathParts.slice(0, -1) : pathParts;

  // Función para capitalizar la primera letra
  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Verificar si la cadena está vacía
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="bread-crum">
      <Link to="/" className="bread-home">
        Home <em className="em-home"> / </em>
      </Link>
      {breadcrumbParts.map((part, index) => {
        // Verifica si el segmento contiene números y omítelo si es necesario
        if (/\d/.test(part)) {
          return null; // Omitir si contiene números
        }

        // Crear la ruta completa hasta el segmento actual
        const path = `/${breadcrumbParts.slice(0, index + 1).join('/')}`;

        // Verificar si existe un nombre de ruta en routeNames, si no usar la parte de la URL directamente
        const routeLabel = routeNames[path] || capitalizeFirstLetter(part.replace(/-/g, ' '));

        // Mostrar "Todos los productos" si estamos en "/productos"
        if (path === '/productos') {
          return (
            <Link key={index} to={path} className="bread-link">
              {routeLabel}
              <em className="em-later"> / </em>
            </Link>
          );
        }

        // Mostrar la categoría solo una vez si existe
        if (category && part === category && index === breadcrumbParts.length - 2) {
          return (
            <Link key={index} to={`/productos/${category}`} className="bread-category">
              {capitalizeFirstLetter(category.replace(/-/g, ' '))}
              <em className="em-later"> / </em>
            </Link>
          );
        }

        // Mostrar el producto solo una vez en el último segmento
        if (productName && part === productName && index === breadcrumbParts.length - 1) {
          return (
            <span key={index} className="bread-product">
              {capitalizeFirstLetter(productName.replace(/-/g, ' '))}
            </span>
          );
        }

        // En caso de que no se cumpla ninguna de las condiciones anteriores, mostrar un enlace normal
        return (
          <Link key={index} to={path} className="bread-link">
            {routeLabel}
            {index < breadcrumbParts.length - 1 && <em className="em-later"> / </em>}
          </Link>
        );
      })}
    </div>
  );
};

export default BreadCrum;
