import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar.jsx';
import { ProductProvider } from './context/ProductContext.js';
import { CartProvider } from './context/cartContext.js';
import Checkout from './components/checkout/Checkout.jsx';
import HomePage from './components/HomePage.jsx';
import Footer from './components/footer/Footer.jsx';
import Shop from './components/shop/Shop.jsx';
import FAQs from './components/FAQs/FAQs.jsx'
import DetailProduct from './components/detailProduct/DetailProduct.jsx';
import Login from './components/login/Login.jsx'
import { AuthProvider } from './context/authAdmin.js';
import PanelAdmin from './components/admin/PanelAdmin.jsx';
import { FeaturedProductsProvider } from './context/featuredProducts.js';
import SuccessPage from './components/success/SuccessPage.jsx';
import { PaymentMethodsProvider } from './context/methodsPayments.js';
import ScrollToTop from './components/scrollToTop/scrollToTop.jsx'
import WhatsAppButton from './components/icons/WspIcon.jsx';

function App() {

  const categories = [
    'Remeras Oversize',
    'Musculosas',
    'Puperas',
    'Buzos Frizados',
  ];

  return (
    <ProductProvider>
    <FeaturedProductsProvider>
    <AuthProvider>
    <CartProvider>
    <PaymentMethodsProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar categories={categories}/>
        <Routes>
          <Route path="/" element={<HomePage categories={categories}/>} />
          <Route path="/productos" element={<Shop />} />
          <Route path="/productos/:category" element={<Shop/>} />
          <Route path="/productos/:category/:productName/:pid" element={<DetailProduct />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<PanelAdmin />} />
          <Route path="/pago-completado" element={<SuccessPage />} />
        </Routes>
        <Footer />
        <WhatsAppButton />
      </BrowserRouter>
    </PaymentMethodsProvider>
    </CartProvider>
    </AuthProvider>
    </ FeaturedProductsProvider>
    </ProductProvider>
  );
}

export default App;