import React, { useRef, useState, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import '../../styles/detailProduct/productGallery.css';

const ProductGallery = ({ producto }) => {
    const galleria = useRef(null);
    const [numVisible, setNumVisible] = useState(5);
    const [thumbnailsPosition, setThumbnailsPosition] = useState('left');
    const [activeIndex, setActiveIndex] = useState(0); // Índice de imagen actual
    const [loupePosition, setLoupePosition] = useState({ x: 0, y: 0 }); // Posición de la lupa
    const [isZoomActive, setIsZoomActive] = useState(false); // Estado para el zoom

    // Extraemos las imágenes y el título del objeto producto
    const { images, title } = producto || {};

    // Función para determinar numVisible y thumbnailsPosition en función del ancho de la pantalla
    const determineResponsiveSettings = () => {
        if (window.innerWidth >= 1024) {
            setNumVisible(5);
            setThumbnailsPosition('left');
        } else if (window.innerWidth >= 768) {
            setNumVisible(3);
            setThumbnailsPosition('left');
        } else {
            setNumVisible(1);
            setThumbnailsPosition('bottom');
        }
    };

    useEffect(() => {
        if (producto && images) {
            determineResponsiveSettings();

            const handleResize = () => {
                determineResponsiveSettings();
            };

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [producto, images]);

    // Manejar el movimiento del mouse para el efecto de lupa
    const handleMouseMove = (e) => {
        if (!isZoomActive) return; // Solo mover la lupa si el zoom está activo

        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left; // Coordenada X del mouse relativa a la imagen
        const y = e.clientY - rect.top;  // Coordenada Y del mouse relativa a la imagen

        setLoupePosition({ x, y });
    };

    // Manejar clic en la lupa para activar/desactivar el zoom
    const handleZoomToggle = () => {
        setIsZoomActive(!isZoomActive); // Cambiar el estado del zoom
    };

    // Plantilla para mostrar la imagen principal
    const itemTemplate = (item) => {
        return (
            <div 
                className="items-main-gallery"
                onMouseMove={handleMouseMove}
                onClick={handleZoomToggle} // Permitir el clic en toda la imagen para activar/desactivar el zoom
            >
                {/* Imagen principal */}
                <img 
                    src={item.itemImageSrc} 
                    alt={item.alt} 
                    className={`galleria-image ${isZoomActive ? 'zoomed' : ''}`} 
                />
                

                {/* Efecto lupa que sigue al cursor */}
                {isZoomActive && (
                    <div 
                        className="loupe-effect"
                        style={{
                            top: `${loupePosition.y - 50}px`,
                            left: `${loupePosition.x - 50}px`,
                            backgroundRepeat: 'no-repeat',
                            backgroundImage: `url(${item.itemImageSrc})`,
                            backgroundPosition: `-${loupePosition.x * 0.8}px -${loupePosition.y * 0.8}px`,
                            backgroundSize: '600%'
                        }}
                    />
                )}
            </div>
        );
    };

    // Plantilla para las miniaturas
    const thumbnailTemplate = (item) => {
        return (
            <div className="thumbnailTemplate">
                <img src={item.itemImageSrc} alt={item.alt} />
            </div>
        );
    };

    // Aseguramos que las imágenes sean un array y seleccionamos hasta 10 imágenes
    const imagesToShow = Array.isArray(images) ? images.slice(0, 10) : [];

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    return (
        <div className="galleria-container">
            {imagesToShow.length > 0 ? (
                <Galleria
                    ref={galleria}
                    value={imagesToShow.map(image => ({
                        itemImageSrc: image,
                        alt: title
                    }))}
                    responsiveOptions={responsiveOptions}
                    numVisible={numVisible}
                    circular
                    autoPlay={false}
                    transitionInterval={600} // Intervalo de transición suave
                    thumbnailsPosition={thumbnailsPosition}
                    item={itemTemplate}
                    thumbnail={thumbnailTemplate}
                    style={{ width: '100%' }}
                    activeIndex={activeIndex}
                    onItemChange={(e) => setActiveIndex(e.index)}
                    showThumbnails
                />
            ) : (
                <p>No hay imágenes disponibles para este producto.</p>
            )}
        </div>
    );
};

export default ProductGallery;
