import { useState, useEffect } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';

const useProducts = (category, page, limit, selectedColors = [], sortOrder) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {

        // Construimos los parámetros de la consulta para la URL
        const queryParams = new URLSearchParams({
          page,
          limit,
          category: category || '',
          colors: selectedColors.length > 0 ? selectedColors.join(',') : '', // Colores separados por comas
          sort: sortOrder !== 'Predeterminado' ? sortOrder : '',
        });

        const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/productos?${queryParams}`);
 
        if (!response.ok) {
          throw new Error('Error fetching products');
        }
        const data = await response.json();
     
        setProducts(data.docs);
        setHasNextPage(data.hasNextPage);
        setTotalRecords(data.totalDocs)
      } catch (err) {
        setError(err.message);
        console.error('Error fetching products:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category, page, limit, selectedColors, sortOrder]);

  return { products, loading, error, hasNextPage, totalRecords };
};

export default useProducts;
