import React from 'react';
import '../../styles/checkout/checkout.css';
import PaymentMethods from './PaymentMethods';

const SummaryContainer = ({ cart }) => {

  return (
    <div className="summary-container">
      <div className='summary-child-container'>
        <div className="summary-header">
          <h3>Resumen de la Compra</h3>
        </div>
        <ul className="product-list-checkout">
        {cart.items.length < 1 ? (
          <p>No hay productos en el carrito.</p>  // Mensaje cuando no hay productos
        ) : (
          <ul className="product-list-checkout">
            {cart.items.map(product => (
              <li key={product._id}>
                <span>{product.title}</span> - <span>x{product.quantity}</span> - <span>{product.tailSelected}</span> - <span>{product.colorSelected}</span>  
                <span>
                  {product.price ? 
                    new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(product.price) 
                    : 'N/A'} 
                </span>
              </li>
            ))}
          </ul>
        )}
        </ul>
      </div>

      <div className="total-container">
        <p>
          Costo de envío: 
          <span className='span-cost-shipping'>
            {cart.shippingCost ? 
              new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(cart.shippingCost) 
              : ' -'}
          </span>
        </p>
        <p>
          Total: 
          <span className='span-cost-shipping'>
            {cart.totalPrice ? 
              new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(cart.totalPrice) 
              : 'N/A'}
          </span>
        </p>
      </div>

      <div className='container-payment-methods'>
        <PaymentMethods totalPrice={cart.totalPrice} />
      </div>


      <div className="security-message">
        <h3>Información de Seguridad de Pago</h3>
        <p>
          🔒 Esta es una página segura que utiliza SSL/TLS (Secure Socket Layer/Transport Layer Security) para cifrar y transmitir sus datos de pago de forma segura. 
          Mercado Pago utiliza protocolos de encriptación y cumple con los estándares de seguridad de la industria para asegurar que cada transacción sea segura y confiable.
        </p>
      </div>
    </div>
  );
};

export default SummaryContainer;
