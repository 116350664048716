import React, { createContext, useContext, useState } from 'react';
import useProducts from '../hooks/productosHook/UseProducts.js';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config.js';

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [category, setCategory] = useState(null);
  const [page, setPage] = useState(1); 
  const limit = 10; 
  const [selectedColors, setSelectedColors] = useState([]); 
  const [sortOrder, setSortOrder] = useState('Predeterminado'); 
  const [product, setProduct] = useState(null);

  const { products, loading, error, hasNextPage, totalRecords } = useProducts(category, page, limit, selectedColors, sortOrder);

  const fetchProductByName = async (pid) => {

    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/productos/id/${pid}`);
      if (!response.ok) {
        throw new Error('Producto no encontrado');
      }
      const data = await response.json();
   
      setProduct(data);  
    } catch (err) {
      console.log(err,'error')
    } 
  };

  return (
    <ProductContext.Provider value={{
      products,
      product,
      fetchProductByName,
      loading,
      error,
      hasNextPage,
      totalRecords,
      page,
      setPage,
      setCategory,
      setSelectedColors,
      setSortOrder
    }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => useContext(ProductContext);
