import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';
import '../../styles/filter/filter.css';

const Filter = ({ categories, scrolled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const toggleCategoryDropdown = () => setIsCategoryOpen(!isCategoryOpen);

  // Función para transformar las categorías a formato URL
  const formatCategoryUrl = (category) => {
    return category.toLowerCase().replace(/\s+/g, '-');
  };

  return (
    <div className="filter-container">
      <button className={`filter-button ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span className={`bar ${scrolled ? 'menu-scrolled' : ''}`} />
        <span className={`bar ${scrolled ? 'menu-scrolled' : ''}`} />
        <span className={`bar ${scrolled ? 'menu-scrolled' : ''}`} />
      </button>

      {isOpen && (
        <ul className="filter-dropdown-list">
          <Link to="/" onClick={() => setIsOpen(false)}>
            <li className="li-filter">Inicio</li>
          </Link>

          <li className="li-filter dropdown" onClick={toggleCategoryDropdown}>
            <span>Categorías</span>
            <FaChevronDown className={`dropdown-icon ${isCategoryOpen ? 'open' : ''}`} />
            {isCategoryOpen && (
              <ul className="sub-dropdown-list">
                <Link to="/productos" onClick={() => setIsOpen(false)}>
                  <li className="li-filter">Todos</li>
                </Link>
                {categories.map((category, index) => (
                  <Link 
                    to={`/productos/${formatCategoryUrl(category)}`} 
                    key={index} 
                    onClick={() => setIsOpen(false)}
                  >
                    <li className="li-filter">{category}</li>
                  </Link>
                ))}
              </ul>
            )}
          </li>

          <Link to="/faqs" onClick={() => setIsOpen(false)}>
            <li className="li-filter">FAQs</li>
          </Link>
          <Link to="/faqs" onClick={() => setIsOpen(false)}>
            <li className="li-filter">Sobre nosotros</li>
          </Link>

          <li className="li-filter"><a href="https://wa.me/5492342514576" className='a-filter' target="_blank" rel="noopener noreferrer">Contacto</a></li>

        </ul>
      )}
    </div>
  );
};

export default Filter;
