import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/navbar/navbar.css'
import Filter from '../filter/Filter';
import { GiShoppingCart } from "react-icons/gi";
import CartDrawer from './CartDrawer.jsx';
import { useCart } from '../../context/cartContext.js';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

function Navbar( {categories} ) {
  const { cartQuantity} = useCart();
  const [isScrolled, setIsScrolled] = useState(false);
  const cartIconRef = useRef(null);

  const handleScroll = () => {
    const offset = window.scrollY;
    setIsScrolled(offset > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  /*CART*/
  const [isCartOpen, setIsCartOpen] = useState(false);


  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };


  return (
    <section className='header-container'>
      <div className={`header ${isScrolled ? 'scrolled' : ''}`}>
        <nav className={`navbar ${isScrolled ? 'black' : ''}`}>

          <div className='menu-hamburguer'>
            <Filter categories={categories} scrolled={isScrolled} />
          </div>

          <div className="logo">
            <Link to={'/'}>
              <img
                src={isScrolled ? '/logotypeIndisNegroRemove.webp' : '/logotypeIndisBlancoRemove.webp'} 
                className={`logotype ${isScrolled ? 'scrolled-logo' : ''}`}
                alt='logotypo'
              />
            </Link>
          </div>

          <div className="nav-links-container">
            <ul className={`nav-links ${isScrolled ? 'scrolled-navlinks' : ''}`}>

              <li className='links-nav links-social'>
                <a href="https://wa.me/5492342514576" target="_blank" rel="noopener noreferrer" className="whatsapp">
                  <FaWhatsapp size={20} />
                </a>
                <a href="https://www.instagram.com/indisindumentaria" target="_blank" rel="noopener noreferrer" className="instagram">
                  <FaInstagram size={20} />
                </a>
              </li>
              
              <div className="cart-icon" onClick={toggleCart} ref={cartIconRef}>
                <div className={`icon-carrito-prev ${isScrolled ? 'scrolled-cart' : ''}`}>
                  <GiShoppingCart />
                </div>

                <div className="notification">
                  {cartQuantity > 0 && <span >{cartQuantity}</span>}
                </div>
               
              </div>

              <div className='itemCart'>
                <CartDrawer isOpen={isCartOpen} onClose={toggleCart} cartIconRef={cartIconRef} />
              </div>
            </ul>
          </div>

        </nav>
      </div>
    </section>
  );
}

export default Navbar;