import React, { useEffect, useState } from 'react';

import {  useCart } from '../../context/cartContext.js';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';
import MercadoPagoWallet from '../MercadoPago/AccountMoneyMP.jsx';

const Pago = ({ personalInfo, shippingInfo, billingInfo }) => {
  const { cart } = useCart();
  const [preferenceId, setPreferenceId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    console.log(cart,'cart en pgo')
    const handleCheckoutARS = async () => {
      try {
        const createObjectFromCart = (cart) => {
          const mapeoCart = cart.items.map((item) => ({
            id: item._id,
            title: item.title,
            category_id: item.category,
            description: item.description,
            quantity: item.quantity,
            unit_price: item.price,
          }));


          return {
            carrito: mapeoCart,
            items: cart,
            client: personalInfo,
            shipping: shippingInfo,
            billing: billingInfo,
          };
        };

        const orderJson = createObjectFromCart(cart);

        const createOrderResponse = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/mercado-pago/create-order`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(orderJson),
        });

        if (!createOrderResponse.ok) {
          throw new Error('Error al crear la orden en Mercado Pago.');
        }
 
        const { id } = await createOrderResponse.json();
        setPreferenceId(id);
        setLoading(false);
     
      } catch (error) {
        console.error('Error al procesar el pago en Mercado Pago:', error);
      }
    };

    if (!preferenceId) {
      handleCheckoutARS();
    }
  }, [cart, preferenceId, personalInfo, shippingInfo]);


  return (
    <div>
      <MercadoPagoWallet preferenceId={preferenceId} loading={loading} />
    </div>
  );
};

export default Pago;
