import React from 'react';
import '../styles/homePage.css';

// Importar Swiper y los módulos necesarios
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Importar módulos de Swiper que se usarán
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// react-icons
import { FaCreditCard, FaTruck, FaStore } from 'react-icons/fa';

import { useFeaturedProducts } from '../context/featuredProducts.js';
import { Link } from 'react-router-dom';

const HomePage = ( {categories} ) => {

  const { featuredProducts } = useFeaturedProducts(); 

  const promotions = [
    { 
        text: <>💥 ¡Aprovechá! 15% OFF en nuestra colección por apertura 🔥</>, 
        image: "remOver1.jpg" 
    },
    { 
      text: (
        <>
          🚚 ¡Llevá 3 productos de la misma categoría y te lo mandamos GRATIS! 
          <br />
          Solo en <Link className='links-categoria' to="/productos/remeras-oversize">Remeras Oversize</Link>, 
          <Link className='links-categoria' to="/productos/puperas"> Puperas</Link> y 
          <Link className='links-categoria' to="/productos/musculosas"> Musculosas</Link>
        </>
      ),
        image: "remOver2.jpg" 
    },
    { 
        text: <>Elegí 3 productos de categorías distintas y escribinos por WhatsApp para un descuento exclusivo 😉 ¡Solo para vos!</>, 
        image: "remOver3.jpg" 
    },
  ];

  const formatCategoryUrl = (category) => {
    return category.toLowerCase().replace(/\s+/g, '-');
  };

  return (
    <div className="homepage">

      {/* Hero Section */}
      <section className="hero-section">

        <div className="hero-content">
          <h1>¡Bienvenido/a!</h1>
          <h2>La moda unisex al mejor precio.</h2>
          <h3>Descubrí promociones exclusivas y crea tu look personalizado para obtener descuentos adaptados a vos.</h3>
          <Link to="/productos" className="cta-button">Ver colección</Link>
        </div>
        
        <div className="hero-image">
          <div className="hero-card">
            <h2>Arma tu propio Look</h2>
            <h3>¡ Recibí un <span style={{ color: '#FF6B6B' }}>descuento personalizado</span> en tu compra !</h3>
            <p>Selecciona 3 prendas de nuestra colección y envíanos los nombres por WhatsApp para obtener un descuento personalizado. Es fácil y rápido:</p>
            <ul style={{ textAlign: 'left', margin: '0 20px' }}>
              <li><strong>Paso 1:</strong> Elegí tus prendas favoritas.</li>
              <li><strong>Paso 2:</strong> Envía los nombres por WhatsApp.</li>
              <li><strong>Paso 3:</strong> ¡Recibí tu descuento al instante!</li>
            </ul>
            <Link to="/productos"  className='button-main-collection'>¡Mirar la colección!</Link>
          </div>
        </div>

      </section>

      <section className="categories-grid">
      <h2>CATEGORÍAS MÁS VISITADAS</h2>
        <div className="categories-container">
          {categories.map((category, index) => (
            <Link 
              key={index} 
              to={`/productos/${formatCategoryUrl(category)}`} 
              className="category-link"
            >
              <div className="category-card">
                <h3>{category}</h3>
              </div>
            </Link>
          ))}
        </div>
      </section>

      {/* Sección de Promociones */}
      <section className="promo-section">
        <div className="promo-grid">
          {promotions.map((promo, index) => (
            <div key={index} className="promo-item">
              <img src={promo.image} alt={`Promoción ${index}`} />
              <h3>{promo.text}</h3>
            </div>
          ))}
        </div>
      </section>

      {/* Productos Destacados (con Swiper) */}
      <section className="featured-products-section">
        <h2>PRODUCTOS DESTACADOS</h2>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]} 
          spaceBetween={20}
          slidesPerView={1}
          navigation 
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }} 
          breakpoints={{
            300: { slidesPerView: 1 },
            600: { slidesPerView: 2 },
            800: { slidesPerView: 3 },
            1024: { slidesPerView: 4 },
          }}
        >
          {featuredProducts.map((product) => (
            <SwiperSlide key={product._id}>
              <Link to={`/productos/${product.category}/${product.title}/${product._id}`}>
                <div className="product-card-featured">
                  <img src={product.images[0]} alt={product.title} />
                  <h3>{product.title}</h3>
                  <p>${product.price}</p>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      {/* Info section */}

      <section className="info-section">
        <h2>Información Importante</h2>
        <div className="info-grid">
          <div className="info-item">
            <FaCreditCard className="info-icon" />
            <h3>Métodos de Pago</h3>
            <p>Ofrecemos múltiples métodos de pago seguros, incluyendo tarjetas de crédito, débito y PayPal.</p>
          </div>
          <div className="info-item">
            <FaTruck className="info-icon" />
            <h3>Tiempos de Envío</h3>
            <p>Los envíos se procesan en un plazo de 3 a 5 días hábiles, dependiendo de tu ubicación.</p>
          </div>
          <div className="info-item">
            <FaStore className="info-icon" />
            <h3>Sin Local Físico</h3>
            <p>No contamos con un local físico, pero estamos aquí para ayudarte en línea. ¡Contáctanos para cualquier consulta!</p>
          </div>
        </div>
      </section>

      {/* Newsletter */}
      <section className="newsletter-section">
        <div className="newsletter-content">
          <h2>Suscríbete a nuestro Newsletter</h2>
          <p>Recibe ofertas exclusivas y las últimas novedades directamente en tu correo.</p>
          <form className="newsletter-form">
            <input type="email" placeholder="Ingresa tu email" />
            <button className='btn-newsletter' type="submit">Suscribirse</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
