import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../../context/cartContext';
import { FiTrash2 } from 'react-icons/fi';
import useDeleteCart from '../../hooks/carritoHook/useDeleteCart';
import { AiOutlineLoading } from 'react-icons/ai';
import '../../styles/cart/carrito.css';

const Cart = ({ onClose }) => {
  const { cart, removeItem, refreshCart, loadingCart } = useCart();
  const { loadingDelete } = useDeleteCart();

  const hasProducts = cart.items && cart.items.length > 0;

  useEffect(() => {
    refreshCart(); 
  }, [cart.items, loadingDelete]);

  const handleDeleteProduct = (productID) => {
    removeItem(productID); 
  };


  const handleCheckout = () => {
    onClose();
  };

  if (!hasProducts) {
    return (
      <div className="CartPage">
        <h4 className="cart-title-no">No hay items en el carrito</h4>
        <Link to="/productos" className="Option">Ver productos</Link>
      </div>
    );
  }

  return (
    <section className="carrito">
      <div className="CartPage">
        <ul>
          {cart.items.map((product) => (
            <li className="CartItem" key={product._id}>
              <div className="lis">
                <div className="divContent">
                  <p>{product ? product.title : 'Producto sin título'}</p>
                  <div className="price-text-content">
                  <p className="p-text">
                    {product.price ? 
                      new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(product.price) 
                      : 'N/A'} 
                  </p>
                  
                    <span className='color-selected'>{product.colorSelected}</span> - <span className='color-selected'>{product.tailSelected}</span>
                    
                  </div>
                </div>

                <div className='quantity-items'>
                  <p>x</p>
                  <em>{product.quantity}</em>
                </div>

                {loadingCart ? (
                  <div className="spinner-container">
                    <AiOutlineLoading className="spinner-icon" />
                  </div>
                ) : (
                  <button onClick={() => handleDeleteProduct(product._id)} className="buttonClear">
                    <FiTrash2 />
                  </button>
                )}

                <div className="product-image">
                  {product.images?.[0] && (
                    <img
                      src={product.images[0]}
                      alt={`Imagen de ${product.title}`}
                    />
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="precio">

        <div className="container-total-price">
          <h3 className="first-child-total-price">TOTAL <em>$ {cart.totalPrice} ARS</em></h3>
        </div>

        <Link to="/checkout" className="checkout">
          <button onClick={handleCheckout}>FINALIZAR COMPRA</button>
        </Link>
      </div>
    </section>
  );
};

export default Cart;
